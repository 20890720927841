@import '~antd/dist/antd.css';

.two{ 
    position: fixed;
    top: 0;
    z-index: 1;
    height: 100vh;
    width: 100vw; 
    background-color: rgba(0,0,0, 0.4 );

} 
.drawer {
    font-family: HYHei;
}
.two .card{
    margin: 0 auto;
    width: 80vw;
    height: 70vh;
    background: rgba(255,255,255 );
    opacity: 1;
    border-radius: 16px; 
    margin-top: 80px;
    display: flex; 
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: scroll;
}

.two .btn{
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
}
.two .btn div{ 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 36px;
    background: rgba(117,94,239 );
    box-shadow: 0px 0px 10px rgba(167,167,167,0.16);
    opacity: 1;
    border-radius: 24px; 
    font-size: 16px;
    font-weight: 500;
    line-height: 31px;
    opacity: 1;
}

.two .btn .save{ 
    background: rgba(117,94,239 ); 
    color: #FFFFFF;
}

.two .btn .share{ 
    background: #F35A6E; 
    color: #FFFFFF;
}

.two .btn .back{ 
    background: rgba(238,238,238 );
    color: #3E3E3E;
}

.two .save-tip{ 
    font-size: 12px;
    color: #666;
}


.two .stage{
    z-index: -99;
}

.two .result{
    width: 270px;
    height: 360px;
}