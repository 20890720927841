@import '~antd/dist/antd.css';
.main{ 
    width: 100vw; 
    height: 100vh;
    overflow: hidden; 
    background-color: #f9f9f9;
}
.one{ 
    width: 100vw;
    height: 100vh;
    
    padding-top: 50px;
    overflow-y: scroll;
}

.card{  
    margin: 0 auto;
    width: 320px;
    height: 426px;
    background: rgba(255,255,255,0.95);
    box-shadow: 0px 3px 16px rgba(138,138,138,0.16);
    opacity: 1;
    border-radius: 16px;
    padding: 20px;  

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    background-repeat: no-repeat; 
    background-size: cover;
    background-position: center;
} 

.card div .tip{ 
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    color: #aaa;
}

.card div .input-text{  
    margin-top: 20px; 
}

.card .bottom input{
    text-align: right;
}

.button-list{
    margin: 0 auto;
    display: flex;
    justify-content: space-between; 
    width: 288px;
}

.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 39px; 
    opacity: 1;
    border-radius: 26px;
    box-shadow: 0px 3px 16px rgb(138 138 138 / 16%);
    margin-top: 50px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 2px;
}

.button.red{ 
    background: #F35A6E; 
}

.button.purple{  
    background: rgba(117,94,239 ); 
}
.ant-upload{ 
    color: #ffffff!important;
}

@font-face{
    font-family: HYHei; 
    src: url('https://cdn.jinzhongtao.com/wish/YangRenDongZhuShiTi-Light.ttf') 
}

.stage{
    margin-top: 100vh;
}

.template{
    display: flex; 
    overflow-x: scroll;
    padding: 16px;
    align-items: center;
    flex-wrap: nowrap;
}
.template .image{
    margin: 0 15px;
    width: 96px;
    border-radius: 4px;
    box-shadow: 0px 3px 9px rgba(138,138,138,0.5);
} 
.template .arrow{
    flex: 0 0 auto;
    margin: 0 15px;
    width: 96px;
    display: flex;
    justify-content: center;
} 

.template .image.check{
    transform: scale(1.1);
}

.template .item {
    position: relative;
}

.template .icon{
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
}

.text{
    font-family: HYHei;
}

.swiper {
    height: 500px;
}

.swiper-slide {
    margin: 0 auto;
    width: 330px;
    height: 440px;
    background: rgba(255,255,255,0.95);
    box-shadow: 0px 3px 16px rgba(138,138,138,0.16);
    opacity: 1;
    border-radius: 16px;

    overflow: hidden;
    padding: 7px 5px;
}


.swiper-slide img{
    width: 320px;
    height: 426px;
}
.swiper-slide.check{
    transform: scale(1.02);
}

.tip{ 
    margin: 0 30px;
    font-size: 12px;
    color: #aaa;
}